<template>
    <div class="w-full">
      <!-- Import file for create suspect -->
      <vs-popup title="Importer suspect" fullscreen :active.sync="popupImportSuspect">
        <div v-if="!(tableData.length && header.length)" class="w-full">
          <import-suspect :onSuccess="loadDataInTable" />
        </div>
        <div v-if="tableData.length && header.length" class="w-full mt-5">
          <div class="w-full flex justify-end border-b">
            <vs-button @click="resetImportFile()" class="mr-2" color="danger" type="border">Vider</vs-button>
            <vs-button @click="createSuspectByimport()">Enregistrer</vs-button> 
          </div>
          <vs-table noDataText="Aucune donnée" stripe pagination :max-items="10" search :data="tableData">
            <template slot="header">
              <h4> <span class="font-semibold">Feuille:</span> {{ sheetName }}</h4>
            </template>

            <template slot="thead">
              <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{ heading }}</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="col" v-for="(col, indexcol) in data[indextr]" :key="indexcol + col">
                  {{ col }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vs-popup>
      <!-- / Import file for create suspect -->
      <!-- create form -->
      <vs-popup title="Créer: suspect" fullscreen :active.sync="popupCreateSuspect">
        <div class="w-full flex justify-end">
          <span class="text-danger mr-1">* </span> = informations requises
        </div>
        <div class="w-full h-12 px-2 mt-2 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
          <div>Informations sur le suspect</div>
        </div>
        <div class="w-full flex px-5">
          <div class="w-1/2 flex flex-col mr-10">
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="status_suspect"><span class="text-danger">*</span>Status du suspect</label>
              <vs-select class="w-3/4" autocomplete v-validate="'required'" v-model="status_suspect" >
                <vs-select-item :key="index" :value="status" :text="status" v-for="(status, index) in ['Nouveau','Non qualifié','En cours de travail','Nurturing']" />
              </vs-select>
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 font-semibold mr-2" for="civilite"><span class="text-danger">*</span>Nom complet</label>
              <vs-select class="w-3/4" label="Civilité" autocomplete v-model="civilite" >
                <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['M.','Mme.','Dr.','Prof.']" />
              </vs-select>
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 font-semibold mr-2" for="prenom"></label>
              <vs-input class="w-3/4" type="text" label="Prénom" placeholder="Prénom" name="prenom" v-model="prenom" />
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 font-semibold mr-2" for="deuxieme_prenom"></label>
              <vs-input class="w-3/4" type="text" label="Deuxième prénom" placeholder="Deuxième prénom" name="deuxieme_prenom" v-model="deuxieme_prenom" />
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 font-semibold mr-2" for="nom"></label>
              <div class="w-3/4 flex flex-col">
                <vs-input class="w-full" type="text" label="*Nom" placeholder="Nom" name="nom" v-validate="'required'" v-model="nom" />
                <span class="text-danger text-sm" v-show="errors.has('nom')">{{ errors.first('nom') }}</span>
              </div>
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="fonction">Fonction</label>
              <vs-input class="w-3/4" type="text" placeholder="" name="fonction" v-model="fonction" />
            </div>
          </div>
          <div class="w-1/2">
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="site_web">Propriétaire du suspect</label>
              <div class="w-3/4"></div>
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="site_web">Site Web</label>
              <vs-input class="w-3/4" type="text" placeholder="" name="site_web" v-model="site_web" />
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="societe"><span class="text-danger">*</span>Société</label>
              <div class="w-3/4 flex flex-col">
                <vs-input class="w-full" type="text" placeholder="" name="societe" v-validate="'required'" v-model="societe" />
                <span class="text-danger text-sm" v-show="errors.has('societe')">{{ errors.first('societe') }}</span>
              </div>
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="secteur">Secteur d'activité</label>
              <vs-select class="w-3/4" autocomplete v-model="secteur" >
                <vs-select-item :key="index" :value="s" :text="s" v-for="(s, index) in ['Agriculture','Vêtements','Banque','Biotechnologie','Produits chimiques','Communications','Construction','Conseil','Formation','Électronique','Énergie','Ingénierie','Divertissement','Environnement','Finance','Alimentation & boissons','Gouvernement','Santé','Hôtellerie','Assurance','Machines','Fabrication','Médias','Non lucratif','Loisirs','Vente au détail','Livraison','Technologie','Télécommunications','Transport','Services','Autre']" />
              </vs-select>
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="nmbre_employes">Nombre d'employés</label>
              <vs-input class="w-3/4" type="text" placeholder="" name="nmbre_employes" v-model="nmbre_employes" />
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="origine">Origine du suspect</label>
              <vs-select class="w-3/4" autocomplete v-model="origine" >
                <vs-select-item :key="index" :value="s" :text="s" v-for="(s, index) in ['Publicité','Événement client','Recommandation d\'un employé','Référence externe','Google AdWords','Partenaire','Autre']" />
              </vs-select>
            </div>
          </div>
        </div>
        <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
          <div>Coordonnées</div>
        </div>
        <div class="w-full flex px-5 py-3">
          <div class="w-1/2">
            <h5 class="text-black font-semibold">Adresse</h5>
            <div class="w-full mt-5">
              <vs-textarea row="4" label="Rue" v-model="rue"></vs-textarea>
            </div>
            <div class="w-full flex mt-5">
              <div class="w-3/5 mr-2">
                <vs-input class="w-full" type="text" label="Ville" placeholder="" name="ville" v-model="ville" />
              </div>
              <div class="w-2/5">
                <vs-input class="w-full" type="text" label="Région/Province" placeholder="" name="region" v-model="region" />
              </div>
            </div>
            <div class="w-full flex mt-5">
              <div class="w-3/5 mr-2">
                <vs-input class="w-full" type="text" label="Code postal" placeholder="" name="code_postal" v-model="code_postal" />
              </div>
              <div class="w-2/5">
                <vs-input class="w-full" type="text" label="Pays" placeholder="" name="pays" v-model="pays" />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full flex justify-center border-t mt-5 py-5" style="background-color: rgb(236, 236, 236);">
          <vs-button @click="popupCreateSuspect=false" class="mr-2" color="danger" type="border">Annuler</vs-button>
          <vs-button @click="createSuspectAndNew()" class="mr-2" color="primary" type="border">Enregistrer et Nouveau</vs-button>
          <vs-button @click="createSuspect()">Enregistrer</vs-button> 
        </div>
      </vs-popup>
      <!-- / create form -->
      <!-- update form -->
      <vs-popup :title="`Modifier ${prenom} ${deuxieme_prenom} ${nom}`" fullscreen :active.sync="popupUpdateSuspect">
        <div class="w-full flex justify-end">
          <span class="text-danger mr-1">* </span> = informations requises
        </div>
        <div class="w-full h-12 px-2 mt-2 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
          <div>Informations sur le suspect</div>
        </div>
        <div class="w-full flex px-5">
          <div class="w-1/2 flex flex-col mr-10">
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="status_suspect"><span class="text-danger">*</span>Status du suspect</label>
              <vs-select class="w-3/4" autocomplete v-validate="'required'" v-model="status_suspect" >
                <vs-select-item :key="index" :value="status" :text="status" v-for="(status, index) in ['Nouveau','Non qualifié','En cours de travail','Nurturing']" />
              </vs-select>
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 font-semibold mr-2" for="civilite"><span class="text-danger">*</span>Nom complet</label>
              <vs-select class="w-3/4" label="Civilité" autocomplete v-model="civilite" >
                <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['M.','Mme.','Dr.','Prof.']" />
              </vs-select>
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 font-semibold mr-2" for="prenom"></label>
              <vs-input class="w-3/4" type="text" label="Prénom" placeholder="Prénom" name="prenom" v-model="prenom" />
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 font-semibold mr-2" for="deuxieme_prenom"></label>
              <vs-input class="w-3/4" type="text" label="Deuxième prénom" placeholder="Deuxième prénom" name="deuxieme_prenom" v-model="deuxieme_prenom" />
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 font-semibold mr-2" for="nom"></label>
              <div class="w-3/4 flex flex-col">
                <vs-input class="w-full" type="text" label="*Nom" placeholder="Nom" name="nom" v-validate="'required'" v-model="nom" />
                <span class="text-danger text-sm" v-show="errors.has('nom')">{{ errors.first('nom') }}</span>
              </div>
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="fonction">Fonction</label>
              <vs-input class="w-3/4" type="text" placeholder="" name="fonction" v-model="fonction" />
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="email">Adresse e-mail</label>
              <vs-input class="w-3/4" type="email" placeholder="" name="email" v-model="email" />
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="telephone">Téléphone</label>
              <vs-input class="w-3/4" type="text" placeholder="" name="telephone" v-model="telephone" />
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="mobile">Téléphone mobile</label>
              <vs-input class="w-3/4" type="text" placeholder="" name="mobile" v-model="mobile" />
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="cote">Cote</label>
              <vs-select class="w-3/4" autocomplete v-model="cote" >
                <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Haut','Moyen','Faible']" />
              </vs-select>
            </div>
          </div>
          <div class="w-1/2">
            <div class="w-full flex mt-5">
              <label class="w-1/4 font-semibold mr-2" for="site_web">Propriétaire du suspect</label>
              <div class="w-3/4">
                {{ proprietaire ? proprietaire.nom : '' }}</div>
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="site_web">Site Web</label>
              <vs-input class="w-3/4" type="text" placeholder="" name="site_web" v-model="site_web" />
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="societe"><span class="text-danger">*</span>Société</label>
              <div class="w-3/4 flex flex-col">
                <vs-input class="w-full" type="text" placeholder="" name="societe" v-validate="'required'" v-model="societe" />
                <span class="text-danger text-sm" v-show="errors.has('societe')">{{ errors.first('societe') }}</span>
              </div>
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="secteur">Secteur d'activité</label>
              <vs-select class="w-3/4" autocomplete v-model="secteur" >
                <vs-select-item :key="index" :value="s" :text="s" v-for="(s, index) in ['Agriculture','Vêtements','Banque','Biotechnologie','Produits chimiques','Communications','Construction','Conseil','Formation','Électronique','Énergie','Ingénierie','Divertissement','Environnement','Finance','Alimentation & boissons','Gouvernement','Santé','Hôtellerie','Assurance','Machines','Fabrication','Médias','Non lucratif','Loisirs','Vente au détail','Livraison','Technologie','Télécommunications','Transport','Services','Autre']" />
              </vs-select>
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="nmbre_employes">Nombre d'employés</label>
              <vs-input class="w-3/4" type="text" placeholder="" name="nmbre_employes" v-model="nmbre_employes" />
            </div>
            <div class="w-full flex mt-5">
              <label class="w-1/4 mr-2" for="origine">Origine du suspect</label>
              <vs-select class="w-3/4" autocomplete v-model="origine" >
                <vs-select-item :key="index" :value="s" :text="s" v-for="(s, index) in ['Publicité','Événement client','Recommandation d\'un employé','Référence externe','Google AdWords','Partenaire','Autre']" />
              </vs-select>
            </div>
          </div>
        </div>
        <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
          <div>Coordonnées</div>
        </div>
        <div class="w-full flex px-5 py-3">
          <div class="w-1/2">
            <h5 class="text-black font-semibold">Adresse</h5>
            <div class="w-full mt-5">
              <vs-textarea row="4" label="Rue" v-model="rue"></vs-textarea>
            </div>
            <div class="w-full flex mt-5">
              <div class="w-3/5 mr-2">
                <vs-input class="w-full" type="text" label="Ville" placeholder="" name="ville" v-model="ville" />
              </div>
              <div class="w-2/5">
                <vs-input class="w-full" type="text" label="Région/Province" placeholder="" name="region" v-model="region" />
              </div>
            </div>
            <div class="w-full flex mt-5">
              <div class="w-3/5 mr-2">
                <vs-input class="w-full" type="text" label="Code postal" placeholder="" name="code_postal" v-model="code_postal" />
              </div>
              <div class="w-2/5">
                <vs-input class="w-full" type="text" label="Pays" placeholder="" name="pays" v-model="pays" />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full flex px-5 py-3">
          <div class="w-1/2 mr-10">
            <span class="font-semibold mr-5">Créé par:</span>
            <span class="mr-1">
              {{ proprietaire ? `${proprietaire.nom},` : '' }}
            </span>
            <span>
              {{ createdAt }}
            </span>
          </div>
          <div class="w-1/2">
            <span class="font-semibold mr-5">Dernière modification par:</span>
            <span class="mr-1">
              {{ proprietaire ? `${proprietaire.nom},` : '' }}
            </span>
            <span>{{ updatedAt }}</span>
          </div>
        </div>
        <div class="w-full flex justify-center border-t mt-5 py-5" style="background-color: rgb(236, 236, 236);">
          <vs-button @click="showUpdateSuspectModal(null)" class="mr-2" color="danger" type="border">Annuler</vs-button>
          <vs-button @click="updateSuspectAndNew()" class="mr-2" color="primary" type="border">Enregistrer et Nouveau</vs-button>
          <vs-button @click="updateSuspect()">Enregistrer</vs-button> 
        </div>
      </vs-popup>
      <!-- / update form -->
      <!-- update status -->
      <vs-popup title="Modifier le statut" :active.sync="popupUpdateStatusSuspect">
        <div class="w-full flex font-semibold">
          {{ selecteSuspects.length }} suspect(s) sélectionné(s)
        </div>
        <div class="w-full flex flex-col mt-5">
          <label class="w-full" for="status_suspect"><span class="text-danger">*</span>Status du suspect</label>
          <vs-select class="w-full" autocomplete v-validate="'required'" v-model="status_suspect" >
            <vs-select-item :key="index" :value="status" :text="status" v-for="(status, index) in ['Nouveau','Non qualifié','Traitement','Opportunité']" />
          </vs-select>
        </div>
        <div class="w-full flex justify-end border-t mt-5 py-5 px-3" style="background-color: rgb(236, 236, 236);">
          <vs-button @click="popupUpdateStatusSuspect=false" class="mr-2" color="danger" type="border">Annuler</vs-button>
          <vs-button @click="updateSuspectStatus()">Enregistrer</vs-button> 
        </div>
      </vs-popup>
      <!-- / update status -->
      <vx-card :refreshContentAction="true" @refresh="refreshSuspectList">
        <vs-table noDataText="Aucune donnée" multiple stripe v-model="selecteSuspects" pagination max-items="50" search :data="suspectsData">
          <template slot="header">
            <!-- <vs-button class="mb-4 mr-5 md:mb-0">Nouveau</vs-button> -->
            <div class="btn-group"> 
              <vs-button type="border" @click="popupCreateSuspect=true">Nouveau</vs-button>
              <vs-button type="border" @click="popupImportSuspect=true">Importer</vs-button> 
              <vs-button type="border" @click="showUpdateStatusSuspect()">Modifier le status</vs-button>
            </div>
          </template>
          <template slot="thead">
            <vs-th>Nom complet</vs-th>
            <vs-th>Fonction</vs-th>
            <vs-th>Société</vs-th>
            <vs-th>Téléphone</vs-th>
            <vs-th>Mobile</vs-th>
            <vs-th>Email</vs-th>
            <vs-th>Status</vs-th>
            <vs-th>Propriétaire</vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td  class="hover:underline hover:text-primary">
                  <span @click="viewSuspect(tr._id)">
                    {{  tr.prenom }}
                    {{ tr.deuxieme_prenom }}
                    {{ tr.nom }}
                  </span>
                </vs-td>
                <vs-td>
                  {{ tr.fonction }}
                </vs-td>
                <vs-td>
                  {{ tr.societe }}
                </vs-td>
                <vs-td>
                  {{ tr.telephone }}
                </vs-td>
                <vs-td>
                  {{ tr.mobile }}
                </vs-td>
                <vs-td>
                  {{ tr.email }}
                </vs-td>
                <vs-td>
                  {{ tr.status_suspect }}
                </vs-td>
                <vs-td>
                  {{ tr.nom_proprietaire }}
                </vs-td>
                <vs-td>
                  <feather-icon @click="showUpdateSuspectModal(tr)" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
                  <feather-icon @click="confirmDeleteSuspect(tr._id)" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" />
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
</template>
<script>
import ImportSuspect from './ImportSuspect.vue'
// For custom error message 
import { Validator } from 'vee-validate' 
const dict = { 
  custom: { 
    nom: { required: 'Remplissez le champ nom.', alpha: '' }, 
    societe: { required: 'Remplissez le champ société.', alpha: '' } 
  } 
} 
// register custom messages 
Validator.localize('fr', dict)
export default {
  name: 'Suspect',
  components: {
    ImportSuspect
  },
  data () {
    return {
      selecteSuspects: [],
      popupCreateSuspect: false,
      popupUpdateSuspect: false,
      popupUpdateStatusSuspect: false,
      popupImportSuspect: false,
      search_adresse: null,
      activeUserInfos: null,

      id_suspect: null,
      proprietaire: null,
      status_suspect: 'Nouveau',
      civilite: null,
      nom: null,
      prenom: null,
      deuxieme_prenom: null,
      fonction: null,
      email: null,
      telephone: null,
      mobile: null,
      cote: null,
      site_web: null,
      societe: null,
      secteur: null,
      nmbre_employes: null,
      origine: null,
      rue: null,
      ville: null,
      region: null,
      pays: null,
      code_postal: null,

      createdAt: null,
      updatedAt: null,
      historique_update: [],

      //import for excel
      tableData: [],
      header: [],
      sheetName: ''
    }
  },
  computed: {
    suspectsData () {
      return this.$store.state.suspect.suspects
    }
  },
  methods: {
    viewSuspect (id) {
      this.$router.push(`/commercial/suspect/${id}`)
    },
    loadDataInTable ({ results, header, meta }) {
      this.header = header
      this.tableData = results
      this.sheetName = meta.sheetName
    },
    resetImportFile () {
      this.tableData = []
      this.header = []
      this.sheetName = ''
    },
    refreshSuspectList (card) {
      this.getAllSuspectByUserId()
      card.removeRefreshAnimation(1000)
    },
    showUpdateStatusSuspect () {
      if (this.selecteSuspects.length > 0) {
        this.popupUpdateStatusSuspect = true
      } else {
        this.$vs.notify({
          title: 'Nous avons rencontré un problème',
          text: 'Sélectionnez au moins un enregistrement, puis réessayez.',
          iconPack: 'feather',
          icon: 'icon-error',
          color: 'danger'
        })
      }
    },
    showUpdateSuspectModal (suspect) {
      this.id_suspect = suspect ? suspect._id : null
      this.proprietaire = suspect ? {
        id: suspect.id_proprietaire,
        nom: suspect.nom_proprietaire
      } : null
      this.status_suspect = suspect ? suspect.status_suspect : null
      this.civilite = suspect ? suspect.civilite : null
      this.nom = suspect ? suspect.nom : null
      this.prenom = suspect ? suspect.prenom : null
      this.deuxieme_prenom = suspect ? suspect.deuxieme_prenom : null
      this.fonction = suspect ? suspect.fonction : null
      this.email = suspect ? suspect.email : null
      this.telephone = suspect ? suspect.telephone : null
      this.mobile = suspect ? suspect.mobile : null
      this.cote = suspect ? suspect.cote : null
      this.site_web = suspect ? suspect.site_web : null
      this.societe = suspect ? suspect.societe : null
      this.secteur = suspect ? suspect.secteur : null
      this.nmbre_employes = suspect ? suspect.nmbre_employes : null
      this.origine = suspect ? suspect.origine : null
      this.rue = suspect ? suspect.rue : null
      this.ville = suspect ? suspect.ville : null
      this.region = suspect ? suspect.region : null
      this.pays = suspect ? suspect.pays : null
      this.code_postal = suspect ? suspect.code_postal : null
      this.createdAt = suspect ? suspect.createdAt : null
      this.updatedAt = suspect ? suspect.updatedAt : null

      this.popupUpdateSuspect = !this.popupUpdateSuspect
    },
    createSuspectAndNew () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const payload = {
            id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
            id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
            nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
            status_suspect: this.status_suspect,
            civilite: this.civilite,
            nom: this.nom,
            prenom: this.prenom,
            deuxieme_prenom: this.deuxieme_prenom,
            fonction: this.fonction,
            site_web: this.site_web,
            societe: this.societe,
            secteur: this.secteur,
            nmbre_employes: this.nmbre_employes,
            origine: this.origine,
            rue: this.rue,
            ville: this.ville,
            region: this.region,
            pays: this.pays,
            code_postal: this.code_postal
          }
          this.$vs.loading({
            type:'sound'
          })
          this.$store.dispatch('suspect/createSuspect', payload)
            .then(() => {
              this.$vs.notify({
                title: 'success',
                text: 'enregistrement valider',
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'primary'
              })
              this.resete_data()
              this.$vs.loading.close()
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
            })
        } else {
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: 'Réviser les champs obligatoires(*)',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          console.log(result)
        }
      })
    },
    createSuspect () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const payload = {
            id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
            id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
            nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
            status_suspect: this.status_suspect,
            civilite: this.civilite,
            nom: this.nom,
            prenom: this.prenom,
            deuxieme_prenom: this.deuxieme_prenom,
            fonction: this.fonction,
            site_web: this.site_web,
            societe: this.societe,
            secteur: this.secteur,
            nmbre_employes: this.nmbre_employes,
            origine: this.origine,
            rue: this.rue,
            ville: this.ville,
            region: this.region,
            pays: this.pays,
            code_postal: this.code_postal
          }
          this.$vs.loading({
            type:'sound'
          })
          this.$store.dispatch('suspect/createSuspect', payload)
            .then(() => {
              this.$vs.notify({
                title: 'success',
                text: 'enregistrement valider',
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'primary'
              })
              this.resete_data()
              this.$vs.loading.close()
              this.popupCreateSuspect = false
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
            })
        } else {
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: 'Réviser les champs obligatoires(*)',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          console.log(result)
        }
      })
    },
    createSuspectByimport () {
      new Promise(() => {
        this.$vs.loading({
          type:'sound'
        })
        this.tableData.forEach((suspect, index) => {
          this.$vs.loading({
            type:'sound'
          })
          const payload = {
            id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
            id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
            nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
            status_suspect: suspect.status_suspect ? suspect.status_suspect : 'Nouveau',
            civilite: suspect.civilite ? suspect.civilite : null,
            nom: suspect.nom ? suspect.nom : `suspect-${index + 1}`,
            prenom: suspect.prenom ? suspect.prenom : null,
            deuxieme_prenom: suspect.deuxieme_prenom ? suspect.deuxieme_prenom : null,
            fonction: suspect.fonction ? suspect.fonction : null,
            site_web: suspect.site_web ? suspect.site_web : null,
            societe: suspect.societe ? suspect.societe : `societe-${index + 1}`,
            secteur: suspect.secteur ? suspect.secteur : null,
            nmbre_employes: suspect.nmbre_employes ? suspect.nmbre_employes : null,
            origine: suspect.origine ? suspect.origine : null,
            rue: suspect.rue ? suspect.rue : null,
            ville: suspect.ville ? suspect.ville : null,
            region: suspect.region ? suspect.region : null,
            pays: suspect.pays ? suspect.pays : null,
            code_postal: suspect.code_postal ? suspect.code_postal : null
          }
          this.$store.dispatch('suspect/createSuspect', payload)
            .then(() => {
              this.$vs.notify({
                title: 'success',
                text: 'enregistrement valider',
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'primary'
              })
              this.$vs.loading.close()
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
            })
        })
        this.tableData = []
        this.header = []
        this.sheetName = ''
        this.$vs.loading.close()
      })
    },
    updateSuspect () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const payload = {
            id: this.id_suspect,
            id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
            id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
            nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
            status_suspect: this.status_suspect,
            civilite: this.civilite,
            nom: this.nom,
            prenom: this.prenom,
            deuxieme_prenom: this.deuxieme_prenom,
            fonction: this.fonction,
            email: this.email,
            telephone: this.telephone,
            mobile: this.mobile,
            cote: this.cote,
            site_web: this.site_web,
            societe: this.societe,
            secteur: this.secteur,
            nmbre_employes: this.nmbre_employes,
            origine: this.origine,
            rue: this.rue,
            ville: this.ville,
            region: this.region,
            pays: this.pays,
            code_postal: this.code_postal,
            historique_update: this.historique_update.push({
              id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
              nom: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
              date: Date.now()
            })
          }
          this.$vs.loading({
            type:'sound'
          })
          this.$store.dispatch('suspect/updateSuspect', payload)
            .then(() => {
              this.$vs.notify({
                title: 'success',
                text: 'Suspect modifier',
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'primary'
              })
              this.resete_data()
              this.showUpdateSuspectModal(null)
              this.$vs.loading.close()
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
            })
        } else {
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: 'Réviser les champs obligatoires(*)',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          console.log(result)
        }
      })
    },
    updateSuspectAndNew () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const payload = {
            id: this.id_suspect,
            id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
            id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
            nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
            status_suspect: this.status_suspect,
            civilite: this.civilite,
            nom: this.nom,
            prenom: this.prenom,
            deuxieme_prenom: this.deuxieme_prenom,
            fonction: this.fonction,
            email: this.email,
            telephone: this.telephone,
            mobile: this.mobile,
            cote: this.cote,
            site_web: this.site_web,
            societe: this.societe,
            secteur: this.secteur,
            nmbre_employes: this.nmbre_employes,
            origine: this.origine,
            rue: this.rue,
            ville: this.ville,
            region: this.region,
            pays: this.pays,
            code_postal: this.code_postal,
            historique_update: this.historique_update.push({
              id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
              nom: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
              date: Date.now()
            })
          }
          this.$vs.loading({
            type:'sound'
          })
          this.$store.dispatch('suspect/updateSuspect', payload)
            .then(() => {
              this.$vs.notify({
                title: 'success',
                text: 'Suspect modifier',
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'primary'
              })
              this.resete_data()
              this.showUpdateSuspectModal(null)
              this.popupCreateSuspect = true
              this.$vs.loading.close()
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
            })
        } else {
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: 'Réviser les champs obligatoires(*)',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          console.log(result)
        }
      })
    },
    updateSuspectStatus () {
      const payload = {
        suspects: this.selecteSuspects,
        status_suspect: this.status_suspect
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('suspect/updateSuspectStatus', payload)
        .then(() => {
          this.$vs.notify({
            title: 'Suspect',
            text: `Le statut de ${this.selecteSuspects.length} suspect(s) a été mis à jour.`,
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.popupUpdateStatusSuspect = false
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    confirmDeleteSuspect (id) {
      this.id_suspect = id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Supprimer le suspect',
        text: 'Voulez-vous vraiment supprimer ce suspect ?',
        acceptText: 'Supprimer',
        accept: this.deleteSuspect
      })
    },
    deleteSuspect () {
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('suspect/softdeleteSuspect', this.id_suspect)
        .then(() => {
          this.$vs.notify({
            title: 'Suspect',
            text: 'Le suspect a été supprimé',
            iconPack: 'feather',
            icon: 'icon-trash',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    resete_data () {
      this.id_suspect = null
      this.status_suspect = null
      this.civilite = null
      this.nom = null
      this.prenom = null
      this.deuxieme_prenom = null
      this.fonction = null
      this.email = null
      this.telephone = null
      this.mobile = null
      this.cote = null
      this.site_web = null
      this.societe = null
      this.secteur = null
      this.nmbre_employes = null
      this.origine = null
      this.rue = null
      this.ville = null
      this.region = null
      this.pays = null
      this.code_postal = null
    },
    getAllSuspectByUserId () {
      this.$vs.loading({
        type:'sound'
      })
      const id = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo'))._id : null
      if (id) {
        this.$store.dispatch('suspect/getAllSuspectByUserId', id)
          .then(() => {
            this.$vs.loading.close()
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close()
          })
      } else {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Nous avons rencontré un problème',
          text: 'Impossible de charger la liste des suspect récemment visualisés',
          iconPack: 'feather',
          icon: 'icon-error',
          color: 'danger'
        })
      }
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created () {
    this.activeUserInfo()
    this.getAllSuspectByUserId()
  }
}
</script>